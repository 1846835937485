import React, { useLayoutEffect, useRef, useState } from "react";
import '../css/projects.css';
import Grid from '@material-ui/core/Grid';
import pathFinderPhoto from '../images/path-finder.png';
import cleanArchitectureApplicationPhoto from '../images/CleanArchitectureApplication.jpg';
import cleanArchitecturePhoto from '../images/CleanArchitecture.png';
import chatroomPhoto from '../images/chat-room.jpg';
import chatroom2Photo from '../images/chat-room2.jpg'
import simulatorPhoto from '../images/psychSim.jpg';
import simulatorPhoto2 from '../images/psychSim2.jpg';
import Dialog from '@material-ui/core/Dialog';


function CleanArchitectureDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog onClose={handleClose} open={open} className="dialog">
            <img src={cleanArchitectureApplicationPhoto} alt="Clean Architecture Project" />
            <div className="dialog-line1"></div>
            <div style={{width: '90%', margin: 'auto'}}>
                <h2>Clean Architecture</h2>
                <h3>Technologies: React, .NET(C#), SQL</h3>
                <div className="dialog-line2"></div>
                <p>
                    This project demonstrates Clean Architecture practices and principles using a simple recipe CRUD application.
                </p>
            </div>
            <div className="dialog-buttons">
                <a href="https://github.com/reyno120/Clean-Architecture" target="_blank" rel="noopener noreferrer">
                    <button className="dialog-button2">Code &lt;/&gt;</button>
                </a>
            </div>
        </Dialog>
    );
}


function PathFinderDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog onClose={handleClose} open={open} className="dialog">
            <img src={pathFinderPhoto} alt="Shortest Path Finder Visualization Project" />
            <div className="dialog-line1"></div>
            <div style={{width: '90%', margin: 'auto'}}>
                <h2>Shortest Path Finder Visualization</h2>
                <h3>Technologies: React, Dijkstra's algorithm</h3>
                <div className="dialog-line2"></div>
                <p>
                    This project is an interactive visualization of Dijkstra's algorithm. The user chooses their
                    start and end point and places "walls" in between these two locations and the program
                    will find the shortest distance betweent the two points.
                </p>
            </div>
            <div className="dialog-buttons">
                <a href="https://codesandbox.io/s/nifty-leavitt-01o09" target="_blank" rel="noopener noreferrer">
                    <button className="dialog-button1">View Project</button>
                </a>
                <a href="https://github.com/reyno120/path-finder" target="_blank" rel="noopener noreferrer">
                    <button className="dialog-button2">Code &lt;/&gt;</button>
                </a>
            </div>
        </Dialog>
    );
}


function ChatRoomDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog onClose={handleClose} open={open} className="dialog">
            <img src={chatroom2Photo} alt="Chat Room Project" />
            <div className="dialog-line1"></div>
            <div style={{width: '90%', margin: 'auto'}}>
                <h2>Chat Room Project</h2>
                <h3>Technologies: React, Web Sockets</h3>
                <div className="dialog-line2"></div>
                <p>
                    This chat room implements the popular glassmorphism web design trend in its UI and uses 
                    sockets to make the chat come to life. Users can create a username and join one of five uniquely named chat rooms!
                </p>
            </div>
            <div className="dialog-buttons">
                <a href="https://github.com/reyno120/chat-room" target="_blank" rel="noopener noreferrer">
                    <button className="dialog-button2">Code &lt;/&gt;</button>
                </a>
            </div>
        </Dialog>
    );
}

function PsychSimulatorDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog onClose={handleClose} open={open} className="dialog">
            <img src={simulatorPhoto2} alt="Psych Simulator Project" />
            <div className="dialog-line1"></div>
            <div style={{width: '90%', margin: 'auto'}}>
                <h2>Psych Simulator Project</h2>
                <h3>Technologies: React, Adobe Character Animator</h3>
                <div className="dialog-line2"></div>
                <p>
                    This app is designed to help people become more comfortable talking about mental health issues.
                    With a focus on students and faculty at Purdue University, this app allows the user to choose a role and scenario,
                    then places them in an uncomfortable situation. The user must navigate through different choices, gaining real-time feedback!
                </p>
            </div>
            <div className="dialog-buttons">
                <a href="https://psych-simulator.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                    <button className="dialog-button1">View Project</button>
                </a>
            </div>
        </Dialog>
    );
}


function Projects() {
    const [slide, setSlide] = useState(false);
    const ourRef = useRef(null);
    const [open, setOpen] = useState({
        one: false, 
        two: false, 
        three: false, 
        four: false, 
        five: false, 
        six: false
    });

    useLayoutEffect(() => {
        const topPos = ourRef.current.getBoundingClientRect().top;

        const onScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;
            if(topPos < scrollPos) {
                setSlide(true);
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const handleClickOpen = (button) => {
        switch(button) {
            case 'one':
                setOpen(state => ({...state, one: true}));
                break;
            case 'two':
                setOpen(state => ({...state, two: true}));
                break;
            case 'three':
                setOpen(state => ({...state, three: true}));
                break;
            case 'four':
                setOpen(state => ({...state, four: true}));
                break;
            case 'five':
                setOpen(state => ({...state, five: true}));
                break;
            case 'six':
                setOpen(state => ({...state, six: true}));
                break;
            default:
                break;
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div className="projects">
            <h2 className={`${slide ? "slideRight" : ''}`} ref={ourRef}>Projects</h2>
            <div className="projects-bar"></div>
            <div className="projects-grid">
                <Grid container justify="center">
                    <Grid item xs={12} sm={4} className="card-1">
                        <img src={cleanArchitecturePhoto} alt="Clean Architecture" />
                        <div className="overlay-1">
                            <h3>Clean Architecture</h3>
                            <p>React / .NET(C#) / SQL</p>
                            <button onClick={() => handleClickOpen('one')}>Learn More</button>
                            <CleanArchitectureDialog open={open.one} onClose={handleClose} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} className="card-1">
                        <img src={simulatorPhoto} alt="Psych Simulator Project" />
                        <div className="overlay-1">
                            <h3>Psych Simulator</h3>
                            <p>React / Adobe Character Animator</p>
                            <button onClick={() => handleClickOpen('two')}>Learn More</button>
                            <PsychSimulatorDialog open={open.two} onClose={handleClose} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} className="card-1">
                        <img src={chatroomPhoto} alt="Chat Room Project" />
                        <div className="overlay-1">
                            <h3>Chat Room</h3>
                            <p>React / Socket.IO</p>
                            <button onClick={() => handleClickOpen('three')}>Learn More</button>
                            <ChatRoomDialog open={open.three} onClose={handleClose} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} className="card-1">
                        <img src={pathFinderPhoto} alt="Shortest Path Finder Visualization Project" />
                        <div className="overlay-1">
                            <h3>Shortest Path Finder</h3>
                            <p>React / Dijkstra's Algorithm</p>
                            <button onClick={() => handleClickOpen('four')}>Learn More</button>
                            <PathFinderDialog open={open.four} onClose={handleClose} />
                        </div>
                    </Grid>
                </Grid>
            </div>
            <a href="https://github.com/reyno120" target="_blank" rel="noopener noreferrer">
                <button className="github">Github</button>
            </a>
            <svg width='100%' height='12vh' viewBox="0 0 100 100" preserveAspectRatio="none">
                <polygon className="slanted-poly" fill="white" stroke="none" points="50 75, 100 0, 100 100, 50 100, 0 100, 0 0" />
            </svg>
        </div>
    );
}

export default Projects;