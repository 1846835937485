import photo from '../images/landing.jpg';
import '../css/header.css'

function Header() {
    return (
        <div className="Header">
            <div className="col-1">
                <h1>
                    Hi, I'm <span style={{color: '#00CBF5'}}>Jacob Reynolds.</span><br/>
                    Software Developer.
                </h1>
                <button onClick={() => {document.getElementsByClassName('projects')[0].scrollIntoView();}}>
                    <span style={{position: 'relative', top: '-1px'}}>Projects</span>
                    <svg fill="none" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><line x1="5" x2="19" y1="12" y2="12"/><polyline points="12 5 19 12 12 19"/></svg>
                </button>
                <a href="mailto:jmreynolds03@gmail.com">
                    <button className="contact-button">
                        Contact
                    </button>
                </a>
            </div>

            <div className="col-2">
                <img src={photo} alt="Jacob Reynolds"></img>
            </div>
        </div>
    );
}

export default Header;