import React from "react";
import '../css/contact.css';
import Grid from '@material-ui/core/Grid';
import MailIcon from '../images/icons/mail.svg';
import LinkedInIcon from '../images/icons/linkedin.svg';
import GithubIcon from '../images/icons/github.png';
import ResumeIcon from '../images/icons/resume-icon.svg';

function Contact() {

    return (
        <div className="contact">
            <svg width='100%' height='8vh' viewBox="0 0 100 100" preserveAspectRatio="none">
                <polygon className="slanted-poly" fill="white" points="0 0, 100 0, 100 100, 50 100, 100 100, 0 0" />
            </svg>
            <div className="contact-grid">
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <h2>Contact</h2>
                        <h3>Want to get in touch?</h3>
                        <div className="contact-button-container">
                            <a href="mailto:jmreynolds03@gmail.com">
                                <button>Email Me</button>
                            </a>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className="icons-container">
                        <div className="icons">
                            <div className="icon-1">
                                <a href="mailto:jmreynolds03@gmail.com">
                                    <img src={MailIcon} alt="Mail Icon" /> 
                                    <p>jmreynolds03@gmail.com</p>
                                </a>
                            </div>
                            <div className="icon-2">
                                <a href="https://www.linkedin.com/in/jacob-reynolds-3179a217a" target="_blank" rel="noopener noreferrer">
                                    <img src={LinkedInIcon} alt="LinkedIn" /> 
                                    <p>LinkedIn</p> 
                                </a>
                            </div>
                            <div className="icon-3">
                                <a href="https://github.com/reyno120" target="_blank" rel="noopener noreferrer">
                                    <div className="github-icon">
                                        <img src={GithubIcon} alt="Github"/> 
                                    </div>
                                    <p>Github</p> 
                                </a>
                            </div>
                            <div className="icon-4">
                                <a href="https://docs.google.com/document/d/1mZ0-mC_4cJ7ZHwEJp_2cg4KsY2n_acSzGY3CJF40OXw/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
                                    <img src={ResumeIcon} alt="Resume" className="resume"/> 
                                    <p>Resume</p>
                                </a>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Contact;