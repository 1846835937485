import React, { useLayoutEffect, useRef, useState } from "react";
import '../css/facts.css';
import Grid from '@material-ui/core/Grid';


function Facts() {
    const [slide, setSlide] = useState(false);
    const ourRef = useRef(null);

    useLayoutEffect(() => {
        const topPos = ourRef.current.getBoundingClientRect().top;

        const onScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;
            if(topPos < scrollPos) {
                setSlide(true);
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <div className="facts">
            <h2 className={`${slide ? "slideLeft" : ''}`} ref={ourRef}>Ultra Running</h2>
            <div className="facts-bar"></div>
            <div className="facts-container">
                <div className="facts-content">
                    <p>“Only those who will risk going too far can possibly find out how far one can go.”</p>
                    <p>-  T.S. Eliot</p>
                </div>
            </div>
            <div className="photos-grid">
                <Grid container>
                    <Grid item xs={6} sm={4} className="photo-1"></Grid>
                    <Grid item xs={6} sm={4} className="photo-2"></Grid>
                    <Grid item xs={6} sm={4} className="photo-3"></Grid>
                    <Grid item xs={6} sm={4} className="photo-4"></Grid>
                    <Grid item xs={6} sm={4} className="photo-5"></Grid>
                    <Grid item xs={6} sm={4} className="photo-6"></Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Facts;