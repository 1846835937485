import React, { useLayoutEffect, useRef, useState } from "react";
import '../css/about.css';
import Grid from '@material-ui/core/Grid';
import photo from '../images/greyhouse-edited.jpg';

function About() {
    const [slide, setSlide] = useState(false);
    const ourRef = useRef(null);

    useLayoutEffect(() => {
        const topPos = ourRef.current.getBoundingClientRect().top;

        const onScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;
            if(topPos < scrollPos) {
                setSlide(true);
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return (
        <div className="about">
            <h2 className={`${slide ? "slideLeft" : ''}`} ref={ourRef}>Career</h2>
            <div className="about-bar"></div>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <img src={photo} alt="Jacob Reynolds" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <p>
                        I'm a full-stack software developer at
                        &nbsp;<a href="https://www.jasperengines.com/" target="_blank" rel="noopener noreferrer">Jasper Engines.</a>
                        &nbsp;I love learning new technologies and how we can better solve problems using software!
                    </p>
                    <a href="https://docs.google.com/document/d/1mZ0-mC_4cJ7ZHwEJp_2cg4KsY2n_acSzGY3CJF40OXw/edit?usp=sharing"  target="_blank" rel="noopener noreferrer">
                        <button>Resume</button>
                    </a>
                </Grid>
            </Grid>
        </div>
    );
}
 
export default About;