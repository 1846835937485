import './App.css';
import Header from './components/Header.jsx';
import About from './components/About.jsx';
import Projects from './components/Projects.jsx';
import Facts from './components/Facts.jsx';
import Contact from './components/Contact.jsx';

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <Projects />
      <Facts />
      <Contact />
    </div>
  );
}

export default App;
